<template>
  <v-app>
      <v-main>
          <v-container fluid>
              <v-slide-y-transition mode="out-in">
                  <v-layout column>
                      <router-view />
                  </v-layout>
              </v-slide-y-transition>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
    //
  }),
};
</script>