<template>
    <v-container fluid>
        <app-bar/>
        <div v-if="!salvo">
            <v-toolbar color="orange" dense>
                <v-toolbar-title>Atualização Cadastral | Let's Move Academia</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-spacer class="mt-4"></v-spacer>
            <v-row align="center" justify="center">
                <v-col col="12" md="12" xl="12" xs="12" sm="12">
                    <v-card>
                        <ValidationObserver ref="form" #default="{ handleSubmit }">
                            <v-form
                                @keyup.enter="handleSubmit(salvar)"
                                @submit.prevent="handleSubmit(salvar)"
                                @reset.prevent="reset"
                            >
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <ValidationProvider
                                                #default="{ errors }"
                                                rules="required"
                                                name="CPF"
                                            >
                                                <v-text-field
                                                v-model="cpf"
                                                label="CPF"
                                                color="#FF562B"                                            
                                                outlined
                                                required
                                                :error-messages="errors[0]"
                                                v-mask="'###.###.###-##'"/>
                                            </ValidationProvider>                                        
                                        </v-col>                                    
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                            v-model="rg"
                                            label="RG"
                                            color="#FF562B"
                                            :rules="[(v) => !!v || 'RG é obrigatório']"
                                            outlined
                                            required
                                            :disabled = "!validado"
                                            ref="rg"/>
                                        </v-col>                                    
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="nome"
                                            >
                                            <v-text-field
                                                v-model="nome"
                                                label="Nome"
                                                color="orange"
                                                :error-messages="errors[0]"
                                                outlined
                                                :disabled = "!validado"/>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <ValidationProvider
                                            #default="{ errors }"
                                            :rules="{ required: true }"
                                            name="nascimento"
                                            v-mask="'##/##/####'"
                                            >
                                            <v-text-field
                                                v-model="nascimento"
                                                label="Data Nascimento"
                                                color="#FF562B"
                                                :error-messages="errors[0]"
                                                outlined
                                                :disabled = "!validado"/>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-select
                                            v-model="sexo"
                                            :items="['Masculino', 'Feminino']"
                                            label="Sexo"
                                            color="#FF562B"
                                            :rules="[(v) => !!v || 'Sexo é obrigatório']"
                                            outlined
                                            required
                                            :disabled = "!validado"/>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider
                                            rules="required|email"
                                            mode="passive"
                                            name="Email"                                            
                                            >
                                            <v-text-field
                                                v-model="email"
                                                label="E-mail"
                                                color="#FF562B"
                                                :rules="[(v) => !!v || 'E-mail é obrigatório']"
                                                outlined
                                                required
                                                :disabled = "!validado"
                                                @keydown.space.prevent/>
                                            </ValidationProvider>
                                        </v-col>                                    
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3" xl="3">
                                            <v-text-field
                                            v-model="telefone"
                                            label="Telefone"
                                            color="#FF562B"
                                            :rules="[(v) => !!v || 'Telefone é obrigatório']"
                                            outlined
                                            required
                                            v-mask="'(##)# ####-####'"
                                            :disabled = "!validado"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="9" md="9" xl="9">
                                            <v-text-field
                                            v-model="endereco"
                                            label="Endereço"
                                            color="#FF562B"
                                            :rules="[(v) => !!v || 'Endereço é obrigatório']"
                                            outlined
                                            required
                                            :disabled = "!validado"
                                            >
                                            </v-text-field>
                                        </v-col>                                    
                                    </v-row>    
                                    <!-- <v-row>
                                        <v-col col="12">
                                            <v-select
                                                v-model="objetivo"
                                                :items="objetivos"
                                                label="Qual(is) seu(s) principal(is) objetivo(s) com exercício personalizado?"
                                                color="#FF562B"
                                                :rules="[(v) => !!v || 'Objetivo é obrigatório']"
                                                outlined
                                                multiple
                                                required
                                                :disabled = "!validado">
                                            </v-select>
                                        </v-col>
                                    </v-row> -->
                                </v-card-text>
                                <v-card-actions>
                                    <v-col col="12">
                                        <v-btn color="#FF562B" width="100%" dark type="submit" v-if="validado">
                                            Salvar
                                            <v-icon class="ml-2">mdi-content-save</v-icon>
                                        </v-btn>
                                    </v-col>                  
                                </v-card-actions>
                            </v-form>
                        </ValidationObserver>
                    </v-card>
                </v-col>
            </v-row>
        </div>
            <!-- <avaliacao-treinador v-if="salvo"/> -->
    </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { required, email } from "vee-validate/dist/rules"
import AppBar from '../components/AppBar.vue'
// import Mensagem from '../components/Mensagem.vue'
import _usuario from "../services/usuario/usuario-servico.js"
import _utils from "../utils/utils.js"

// Add the required rule
extend("required", required);

// Add the email rule
extend("email", email);

export default {    
    components: { 
        ValidationObserver,
        ValidationProvider,
        AppBar, 
        // Mensagem,        
    },
    
    computed: {
    _telefone() {
            return this.telefone.replace(/\D/g, "");
        },
        _cpf() {
            return this.cpf.replace(/\D/g, "");
        },
    },

    data() {
        return {
            id:  0,
            cpf: '',
            rg: '',
            nome: '',
            nascimento: '',            
            sexo: '',
            email: '',
            telefone: '',
            endereco: '',
            senha: '$2b$12$KPffG/xe/Gi1VW.E2e.1teSXp1jW8MJgDoBXzcbI577QIs4wY1jWS',
            foto: '',
            instagram: '',
            objetivo: '',
            usuario: '',
            validado: false,
            salvo: false,
            objetivos: ['Qualidade de vida e bem estar', 'Emagrecimento ou perda de peso', 'Hipertrofia ou ganho de massa muscular', 'Condicionamento fisico e saúde', 'Performance ou concurso', 'Saúde mental e emocional'],
        }
    },    
        
    watch: {
        cpf: {
            // immediate: true,
            deep: true,
            async handler (alteracoes) {
                const valorSemPontos = alteracoes.replace(/\D/g, "") // Remove os pontos
                if (valorSemPontos.length === 11) {
                    // Verifica se o comprimento é igual a 11
                    if (this.validaCPF(valorSemPontos)) {
                        this.validado = true;
                        this.$nextTick(() => {
                            this.$refs.rg.focus();
                        });
                    //if (this.proximo) {                        
                        //await this.verificarUsuario(valorSemPontos)
                        await this.buscar(valorSemPontos)                        
                    //}
                    } else {
                        //this.cpf.rules = "X"                    
                    // this.mostrarMensagem({
                    //     tipo: "error",
                    //     descricao: "CPF inválido.",
                    // })
                        alert("CPF inválido");
                        this.limparCampos();
                    }
                }
            },            
        },
        email()
        {
            this.$nextTick(() =>
            {
            this.email = this.email.replace(/\s+/g, '');
            })
        },
    },

    methods: {
        verificarCPF (campo) {
            const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
            return cpfRegex.test(campo)
        },

        validaCPF (cpf) {
            var Soma = 0
            var Resto

            var strCPF = String(cpf).replace(/[^\d]/g, "")

            if (strCPF.length !== 11) { return false }

            if ([
                "00000000000",
                "11111111111",
                "22222222222",
                "33333333333",
                "44444444444",
                "55555555555",
                "66666666666",
                "77777777777",
                "88888888888",
                "99999999999",
            ].indexOf(strCPF) !== -1) { return false }

            for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(9, 10))) { return false }

            Soma = 0

            for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(10, 11))) { return false }

            return true
        },

        buscar(cpf) {            
            _usuario.buscarUsuarioCpf(cpf).then((response) => {                   
                this.usuario = response;
                console.log(this.usuario);
                this.id = this.usuario.id;     
                this.rg = this.usuario.rg;
                this.nome = this.usuario.nome;
                if (this.usuario.nascimento != null)
                    this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);
                if (this.usuario.sexo == "M") {
                    this.sexo = "Masculino";
                } else if (this.usuario.sexo == "F") {
                    this.sexo = "Feminino";
                }                
                this.email = this.usuario.email;
                if (this.usuario.telefone != null)
                    this.telefone = this.usuario.telefone;
                if (this.usuario.endereco != null)
                    this.endereco = this.usuario.endereco;
                // this.instagram = this.usuario.instagram;
                // if (this.usuario.relacionamento == null) this.isDisabled = false;
            });
            if (this.cpf.length == 11 && this.usuario == "")
            {
                alert("Cadastro não encontrado!");
                this.limparCampos();
            }
        },
        salvar(){
            this.usuario = {
                nome: this.nome,
                login: this._cpf,
                senha: this.senha,
                email: this.email,
                telefone: this._telefone,
                nascimento: _utils.formatarData(this.nascimento),
                cpf: this._cpf,
                rg: this.rg,
                sexo: this.sexo.substring(0, 1),
                endereco: this.endereco,
                foto: this.foto,
                instagram: this.instagram,
                objetivo: '',
                precadastro: false,
                nivel_acesso: '',
                sistema: 0
            }
            console.log(this.usuario)            
            this.atualizar();            
            //this.$router.replace({ name: 'avaliacaotreinador', params: { usuario: this.usuario}})            
            //this.$router.push({ name: 'avaliacaotreinador', params: { usuario: this.usuario } })
            this.$router.push({ path: `/atualizacao-mensagem` })
        },        

        atualizar() {
            _usuario.atualizarUsuario(this.id, this.usuario).then(() => {
                this.salvo = true;
            });
        },
        
        salvarUsuario() {
            _usuario.cadastrarUsuario(this.usuario).then(() => {        
                this.salvo = true;
            });      
        },

        limparCampos(){
            //this.cpf = '',
            this.rg = '',
            this.nome = '',
            this.nascimento = '',
            this.sexo = '',
            this.email = '',
            this.telefone = '',
            this.endereco = '',
            this.senha = '$2b$12$KPffG/xe/Gi1VW.E2e.1teSXp1jW8MJgDoBXzcbI577QIs4wY1jWS',
            this.foto = '',
            this.instagram = '',
            this.objetivo = '',
            this.usuario = '',
            this.validado = false,
            this.salvo = false,
            this.objetivos = ['Qualidade de vida e bem estar', 'Emagrecimento ou perda de peso', 'Hipertrofia ou ganho de massa muscular', 'Condicionamento fisico e saúde', 'Performance ou concurso', 'Saúde mental e emocional']
        }
    }
}
</script>