import axios from "axios";

const utils = {
    /**
    * Formata data 
    * 
    * */
    formatarData(data) {
        const [day, month, year] = data.split("/");
        const newDate = `${month}/${day}/${year}`            
        return new Date(newDate).toISOString().replace(/Z/, '');
    },

    formatarDataTela(data) {
        const [year, month, day] = data.substring(0, 10).split("-");
        const newDate = `${day}/${month}/${year}`
        return newDate;
    },

    diaSemana(dia){
        if (dia === 1){
            return "Segunda-feira"
        } else if (dia === 2){
            return "Terça-feira"
        } else if (dia === 3){
            return "Quarta-feira"
        } else if (dia === 4){
            return "Quinta-feira"
        } else if (dia === 5){
            return "Sexta-feira"
        } else if (dia === 6){
            return "Sábado"
        } else if (dia === 7){
            return "Domingo"
        }        
    },

    formataHora(hora){    
        return hora.substring(0, 5)
    },

    sleep(ms) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    },

    removerAcentos(texto) {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    /**
     * Método para baixar arquivos do FTP e por base64
     * Ação de retorno é o download do arquivo em si.
     *
     * os parâmetros são opcionais, o download é feito pelo caminho ou pela base64
     *
     * @param {string} caminho = http://127.0.0.1:8081/Edital/4e98d257-e5cc-4e5a-87ee-a8f0c9c83408.jpg
     * @param {base64} base64 = string em base64. OBS: é necessário passar o (data:application/pdf;base64,) para funcionar corretamente.
     *
     */

    baixarArquivo(caminho = null, base64 = null, nomeArquivo = null) {
        if (caminho !== null && base64 === null) {
            axios({
                url: caminho,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                gerarLinkDownload(
                    nomeArquivo === null ? caminho.split("/").pop() : nomeArquivo,
                    new Blob([response.data]),
                    false
                )
            })
        } else {
            gerarLinkDownload(
                nomeArquivo === null ? Math.random().toString(36).slice(2) : nomeArquivo,
                base64,
                true
            )
        }
    },

    /**
     * Método para setar para a área de transferência
     * Exemplo
     *  <v-btn
            id="idCodigoBoleto" //esse id é o mesmo que é passado por paramêtro                        
            type="button"
            @click="util.copiarAreaTransferencia('idCodigoBoleto', '34191.75306 36579.502042 00173.090002 2 000')"
     * @param {String} id // id o elemento clicado
     * @param {String} valor // valor que será copiado para área de transferência
     */
            async copiarAreaTransferencia(id, valor) {
                var elemento = document.getElementById(id)
        
                await navigator.clipboard.writeText(valor)(function () {
                    const html = `<div id='mensagemCopiar' class='mensagem-copiar mostrar d-flex align-center'>
                                        Copiado para área de transferência. 
                                        <i class='mdi mdi-check-all green--text text-h4 ml-4'/>
                                 </div>`
        
                    elemento.insertAdjacentHTML("beforeend", html)
        
                    const mensagemCopiar = document.querySelectorAll("#mensagemCopiar")
                    setTimeout(() => {
                        mensagemCopiar.forEach(x=>x.remove())
                      }, "2700")
                }, function (err) {
                    console.error("erro ao copiar: ", err)
                })
            },
        
}

function gerarLinkDownload(nomeArquivo, data, ehBase) {
    var fileURL = ehBase
        ? data
        : window.URL.createObjectURL(data)

    var fileLink = document.createElement("a")

    fileLink.href = fileURL
    fileLink.setAttribute("download", ehBase ? nomeArquivo + `.${data.split(";")[0].split("/")[1]}` : nomeArquivo)
    document.body.appendChild(fileLink)

    fileLink.click()
}

// function gerarBase64(valor){
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader()
//         reader.readAsDataURL(valor)
//         reader.onload = () => resolve(reader.result)
//         reader.onerror = error => reject(error)
// })
// }

export default utils