import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes/router'
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate"
import ptBr from "vee-validate/dist/locale/pt_BR.json"
import * as rules from "vee-validate/dist/rules"
import VMask from 'v-mask'

Vue.use(VMask)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize("pt_BR", ptBr)

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
