<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
            <v-img
                :src="require('../assets/icaro-preto.png')"
                class="my-3"
                contain
                height="200"
            />
            </v-col>
            
            <v-col class="mb-4">
            <h2 class="font-weight-bold mb-3">
                Obrigado pela confirmação dos dados.<br/>
                Você receberá os próximos passos via whatsapp.
            </h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default { 
        data: () => ({      
        }),
    }
</script>