import Vue from 'vue'
import Router from 'vue-router'
import Inicial from '../views/Inicial.vue'
import ConnectRegistro from '../views/ConnectRegistro.vue'
import Registro from '../views/Registro.vue'
import Atualizacao from '../views/Atualizacao.vue'
import AtualizacaoMensagem from '../views/AtualizacaoMensagem.vue'
import Avaliacao from '../views/Avaliacao.vue'
import AvaliacaoTreinador from '../views/AvaliacaoTreinador.vue'

Vue.use(Router)    
export default new Router({
    mode: 'history', // Configura o modo de histórico
    routes: [
        {
            path: '/',
            name: 'inicial',
            component: Inicial,
            meta: { title: 'icaro fit'}
        },
        {
            path: '/connect-registro',
            name: 'connectregistro',
            component: ConnectRegistro,
            // meta: { title: 'icaro fit | Registro'}
        },
        {
            path: '/registro',
            name: 'registro',
            component: Registro,
            meta: { title: 'icaro fit | Registro'}
        },
        {
            path: '/atualizacao',
            name: 'atualizacao',
            component: Atualizacao,
            meta: { title: 'icaro fit | Atualização Cadastral'}
        },
        {
            path: '/atualizacao-mensagem',
            name: 'atualizacaomensagem',
            component: AtualizacaoMensagem,
            meta: { title: 'icaro fit | Atualização Cadastral'}
        },
        {
            path: '/avaliacao',
            name: 'avaliacao',
            component: Avaliacao,
            meta: { title: 'icaro fit | Avaliação Experiência Vamo Mexe'}
        },
        {
            path: '/avaliacao-treinador',
            name: 'avaliacaotreinador',
            component: AvaliacaoTreinador,
            meta: { title: 'icaro fit | Avaliação Treinador'},
            props: {}
        },
    ]
})