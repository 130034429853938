<template>
    <div>      
        <v-app-bar color="black" dark app>
            <v-spacer />
            <v-img class="mx-2" src="../assets/connect-branco.png" max-height="150" max-width="150" contain />
            <v-spacer />
        </v-app-bar>
    </div>
</template>
  
<script>
export default {
    data() {
        return {        
        };
    },
    created() {      
    },
    mounted() {      
    },
    computed() {      
    },
    methods: {      
    },
};
</script>