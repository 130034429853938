import axios from "axios"

const api = axios.create({  
  //baseURL: "http://localhost:8000/api/v1/",
  baseURL: "https://api.icaro.fit/api/v1/",
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api