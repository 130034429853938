<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/icaro-preto.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h2 class="display-1 font-weight-bold mb-3">
          Bem-vindo ao icaro fit
        </h2>

        <!-- <p class="subheading font-weight-regular">
          For help and collaboration with other Vuetify developers,
          <br>please join our online
          <a
            href="https://community.vuetifyjs.com"
            target="_blank"
          >Discord Community</a>
        </p> -->
        <!-- <a href="https://app.icaro.fit" style="text-decoration: none;"><img src="https://icaro.fit/img/qr_icaro.png" width="300px"></a>         -->
      </v-col>            
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <a href="https://play.google.com/store/apps/details?id=fit.icaro.app&pcampaignid=web_share" style="text-decoration: none;" target="_blank"><img src="https://icaro.fit/img/google-play-badge.png" width="150px"></a>
      </v-col>
      <v-col cols="12">
        <a href="https://apple.co/3RUcGtX" style="text-decoration: none;" target="_blank"><img src="https://icaro.fit/img/applesotre-badge.svg" width="150px"></a>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({      
    }),
  }
</script>
